<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "us",
  data() {
    return {
      pageName:this.$options.name,
      ziList:[
        { url:require('@/images/zz/1.jpg'), t1:"浩新餐饮", t2:"软著证书",},
        { url:require('@/images/zz/3.jpg'), t1:"浩新娱乐", t2:"软著证书",},
        { url:require('@/images/zz/4.jpg'), t1:"浩新客房", t2:"软著证书",},
        { url:require('@/images/zz/5.jpg'), t1:"浩新会员", t2:"软著证书",},
        { url:require('@/images/zz/6.jpg'), t1:"浩新仓管", t2:"软著证书",},
        { url:require('@/images/zz/7.jpg'), t1:"浩新微信点单", t2:"软著证书",},
        { url:require('@/images/zz/8.jpg'), t1:"浩新XYHIS", t2:"软著证书",},
        { url:require('@/images/zz/2.jpg'), t1:"浩新VOD点播", t2:"软著证书",},
        { url:require('@/images/zz/21.jpg'), t1:"天王产品销售", t2:"授权书",},
        { url:require('@/images/zz/22.jpg'), t1:"知识产权管理", t2:"认证证书",},
        { url:require('@/images/zz/23.jpg'), t1:"高新技术企业", t2:"", class:"li3"},
        { url:require('@/images/zz/24.jpg'), t1:"高新技术企业证书", t2:"", class:"li3"},
        { url:require('@/images/zz/25.jpg'), t1:"浩新视点软著证书", t2:"", class:"li3"},
      ],
      srcList:[],
    };
  },
  methods: {
    
  },
  mounted() {
    this.srcList = this.ziList.map(function(item){return item.url})
  },
  activated() {
    if (this.$route.query.t) {
      let _id = "#t-" + this.$route.name + "-" + this.$route.query.t;
      console.log(_id);

      setTimeout(function() {
        document.querySelector(_id).scrollIntoView(true);
      }, 150);
    }
  }
};
</script>



